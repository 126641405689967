<template>
  <b-container>
    <b-card>
      <div class="row justify-content-center align-items-center">
        <div class="col-xs-10 col-sm-10 col-md-6 col-lg-6">
          <div class="d-flex justify-content-center align-items-center">
            <feather-icon icon="CheckIcon" size="400" class="text-success" />
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <h1>Thank you !</h1>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <h3>Order submitted.</h3>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <b-button
              variant="gradient-primary"
              class="box-shadow-1 w-50"
              href="/dashboard"
              >Back to Dashboard</b-button
            >
          </div>
          <div class="d-flex justify-content-center align-items-center mt-1">
            <b-button
              variant="gradient-primary"
              class="box-shadow-1 w-50"
              href="/on-demand-delivery"
              >Continue Ordering</b-button
            >
          </div>
        </div>
      </div>
    </b-card>
  </b-container>
</template>

<script>
export default {
  components: {},

  data() {
    return {};
  },
};
</script>

<style lang="scss">
@import "src/assets/scss/style.scss";
</style>
